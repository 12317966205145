.wrapper{
    background:rgba(0, 0, 0, 0.548);
    height: 100vh;
    }
    
    .popup-two{
        width: 60%;
        height: 90vh;
        background-color:white;
        position: absolute;
        left: 50%;
        top: 55%;
        transform: translate(-50%,-50%);
        padding-top: 15px;
    }
    .popup-two .link{
        text-align: center;
    }
    .popup-two .link ul li{
        list-style: none;
        display: inline-block;
        margin-left: 20px;
    }
    .popup-two .link ul li a{
        text-decoration: none;
        color: #132a3e;
    }
    .popup-two .btn{
        padding: 10px;
        text-align: center;
    }
    .popup-two .btn h3{
        font-weight: 100;
        font-size: 16px;
        color: #525859;
    }
    .popup-two .btn button{
        width: 90%;
        padding: 10px 0 10px 0;
        margin-top: 15px;
        border-radius: 5px;
        border: none;
        color: white;
        cursor: pointer;
    }
    
    .popup-two .btn button img{
        width: 15px;
        display: inline-block;
       margin-left: 5px;
       vertical-align: -5px;
    }
    .popup-two .btn #one{
    background-color: #1e96c8;
    
    }
    .popup-two .btn #one:hover{
        background-color: #0066ff;
        
        }
    
    .popup-two .btn #two{
    background-color: #d9534f;
    
    }
    .popup-two .btn #two:hover{
        background-color: #db1710;
        
        }
    .popup-two .btn #three{
    background-color: #1683bb;
    
    }
    .popup-two .btn #three:hover{
        background-color: #00a0f0;
        
        }
    
        .popup-two .form{
            padding: 15px;
            text-align: right;
        }
        .popup-two .form form{
            margin-top: 15px;
        }
        .popup-two .form input{
            width: 100%;
            padding: 10px 5px 10px 0;
            margin-top: 20px;
            border-radius: 5px;
            border:1px solid rgba(128, 128, 128, 0.438);
            text-align: right;
           
        }
        .popup-two .form button{
            padding: 10px 17px 10px 17px;
            background-color: #061827;
            color: white;
            border-radius: 5px;
           border: none;
           margin-top: 7px;
           
        }
        .popup-two .form #distance{
            margin-top: 30px;
        }
        .popup-two .form h3{
    font-weight: 100;
    color: #4b5152;
    font-size: 14px;
        }
       .popup-two .form span{
            display: inline-block;
        }
        .popup-two .form input[type="checkbox"]{
           
            width: 12px;
            vertical-align: -4px;
            margin-right: 10px;
        }




        @media only screen and (max-width:990px){
            .popup-two{
                height: auto;
            }
            .wrapper {
               
                height: 111vh;
            }
        }

        @media only screen and (max-width:480px){
            .popup-two{
                width: 85%;
                margin-top: 128px;
            }
            .popup-two .form span{
                font-size: 8px;
            }
            .wrapper {
                height: 140vh;
            }
        }
        @media only screen and (min-width:480px) and (max-width:992px){
            .popup-two{
                width: 85%;
                margin-top: 128px;
            }
            .wrapper {
                height: 140vh;
            }
            .popup-two .form span{
                font-size: 12px;
            }
        }