.navbar{
    width: 100%;
    height: 55px;
    background-color: white;
    box-shadow: 0 0 2px 0 gray;
position: fixed;
top: 0;
left: 0;
right: 0;
    clear: both;
    z-index: 99999;
}
.navbar .menu img{
    width: 20px;
    position: absolute;
    right: 40px;
    top: 15px;
    display: none;
}

.navbar nav{
float: right;
direction: rtl;
}
.navbar nav ul{
    margin-top: 10px;
}
.navbar nav ul li{
    list-style: none;
    display: inline-block;
    margin-right: 30px;
}
.navbar nav ul li a{
    text-decoration: none;
    color: #132a3e;
}
.navbar .second{
    float: left;
    
}
.navbar .second .brand{
    width: 110px;
    margin-top: 15px;
    display: inline-block;
    margin-left: 50px;
}
.navbar .second input{
    border-top: none;
    border-right: 1px solid rgb(167, 163, 163);
    border-left: 1px solid rgb(167, 163, 163);
    border-bottom: none;
    margin-left: 30px;
    height: 100%;
    display: inline-block;
    padding: 10px;
    position: relative;
    top: -3px;
    outline: none;
    direction: rtl;
    
}
.navbar #btn2{
    background-color: #132a3e;
    padding: 8px 10px 8px 10px;
    border-radius: 4px;
}
.navbar #btn2 a{
    color: white;
}

.navbar .second .search{
    width: 15px;
    position: absolute;
    left: 16%;
    top: 23px;
   
}


.pop-search{
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.623);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;
  display: none;
}

.pop-search input{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 80%;
    height: 60px;
    background: transparent;
    border: none;
    border-bottom: 1px solid white;
    outline: none;
    text-align: right;
}
.pop-search input[placeholder]{
color: white;
font-size: 20px;

}
::placeholder{
    color: white;
}
.pop-search #click{
    position: absolute;
   
    top:30px;
    right: 50px;
}
.pop-search img{
    width: 15px;
}


@media only screen and (max-width:992px){
    .navbar nav ul{
        width: 220px;
        position: absolute;
        right: -100%;
        top: 40px;
        background-color: rgb(255, 255, 255);
        height: 100vh;
        z-index: 999;
       padding-top: 50px;

    }
    .navbar .menu img{
        display: block;
    }
    .navbar nav ul li{
        display: block;
        margin-bottom: 20px;
    }
    .navbar #btn2{
        background:none;
        padding:0;
        border-radius: 0;
        
    }
    .navbar #btn2 a{
        color: black;
    }
}

@media only screen and (max-width:1200px){
    .navbar .second .search{
        left: 18%
    }
}

@media only screen and (max-width:992px){
    .second a{
        position: absolute;
        top: 5px;
        left: 36%;
    }

    .navbar .second .search{
        left: 15%;
        top: 20px;
    }
    .navbar .second input{
        top: 5px;
        display: none;
    }

    @media only screen and (max-width:480px){
        .second a{
            left: 20%;
        }
    }


}
@media only screen and (min-width:992px) and (max-width:1085px){
    .navbar .second{
        display: none;
    }
}