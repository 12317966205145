.wrapper{
background:rgba(0, 0, 0, 0.548);
height: 100vh;
}

.popup{
    width: 60%;
    height: 416px;
    background-color:white;
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%,-50%);
    padding-top: 15px;
}
.popup .link{
    text-align: center;
}
.popup .link ul li{
    list-style: none;
    display: inline-block;
    margin-left: 20px;
}
.popup .link ul li a{
    text-decoration: none;
    color: #132a3e;
}
.popup .btn{
    padding: 10px;
    text-align: center;
}
.popup .btn h3{
    font-weight: 100;
    font-size: 16px;
    color: #525859;
}
.popup .btn button{
    width: 90%;
    padding: 10px 0 10px 0;
    margin-top: 15px;
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer;
}

.popup .btn button img{
    width: 15px;
    display: inline-block;
   margin-left: 5px;
   vertical-align: -5px;
}
.popup .btn #one{
background-color: #1e96c8;

}
.popup .btn #one:hover{
    background-color: #0066ff;
    
    }

.popup .btn #two{
background-color: #d9534f;

}
.popup .btn #two:hover{
    background-color: #db1710;
    
    }
.popup .btn #three{
background-color: #1683bb;

}
.popup .btn #three:hover{
    background-color: #00a0f0;
    
    }

    .popup .form{
        padding: 15px;
        text-align: right;
    }
    .popup .form form{
        margin-top: 15px;
    }
    .popup .form input{
        width: 100%;
        padding: 10px 5px 10px 0;
        margin-top: 20px;
        border-radius: 5px;
        border:1px solid rgba(128, 128, 128, 0.438);
        text-align: right;
       
    }
    .popup .form button{
        padding: 10px 17px 10px 17px;
        background-color: #061827;
        color: white;
        border-radius: 5px;
       border: none;
       margin-top: 7px;
       
    }
    .popup .form #distance{
        margin-top: 30px;
    }
    .popup .form h3{
font-weight: 100;
color: #4b5152;
font-size: 14px;
    }



    @media only screen and (max-width:990px){
        .popup{
            height: auto;
        }
        .wrapper {
               
            height: 111vh;
        }
    }
    @media only screen and (max-width:480px){
        .popup{
            width: 80%;
        }
    }