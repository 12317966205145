.category .cat img{
    width: 100%;
    height: 80px;
    border-radius: 6px;
}
.category{
    background-color: white;
}
.category .cat{
    padding: 5px;
    position: relative;
    cursor: pointer;
    
}
.category .cat h4{
    position: absolute;
    top: 46%;
    left: 49%;
    transform: translate(-50%,-50%);
    color: white;
    font-weight: bold;
}
.category h3{
    text-align: right;
    color: #2d3436;
    margin-bottom: 30px;
}
.category h3 a{
    text-decoration: none;
    color: #1bb7d7;
    font-size: 14px;
}