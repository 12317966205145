.all{
    width: 100%;
    height: 100vh;
    background-color: #caf0fc;
}
.all .center{
    width: 30%;
    height: 300px;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
    padding: 10px;
}
.all .center h3{
    margin: 30px 0 15px 0;
    color: #2d3436;
}
.all .center p{
    margin-bottom: 15px;
    font-size: 13px;
}
.all .center input{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: none;
    margin-bottom: 16px;
    text-align: right;
}
.all .center button{
    width: 100%;
    height: 40px;
    border-radius: 5px;
    background-color: #132a3e;
    border: none;
    color: white;
}

@media only screen and (min-width:480px) and (max-width:768px){
    .all .center {
        width: 85%;
    }
}
@media only screen and (max-width:480px){
    .all .center {
        width: 85%;
    }
}