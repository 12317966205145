@font-face{
font-family: yekan;
src: url(./Yekan.woff) format(woff);

}


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: yekan;
  
}
body{
  
  width: 100%;
  overflow-x: hidden;
}
.space{
  clear: both;
}