.background .back-second:before{
    background-color: #0095db;
    -webkit-transform: translateY(0) rotate(30deg);
    transform: translateY(0) rotate(30deg);
    content: "";
    display: block;
    height: 600px;
    left: -25%;
    position: absolute;
    top: 50%;
    width: 150%;
    z-index: -1;
}
.background{
    position: relative;
}
.background .back-second:after{
    background-color: #3ec1ff;
    -webkit-transform: translateY(0) rotate(-30deg);
    transform: translateY(0) rotate(-30deg);
    content: "";
    display: block;
    height: 600px;
    left: -25%;
    position: absolute;
    top: 50%;
    width: 150%;
    z-index: -1;
}

.background .box-midddle{
width: 100%;
height: 400px;
background-color: white;
transform: translateY(55px);
}

.back-second .img{
    text-align: center;
    padding-top: 60px;
}

.back-second .text{
    direction: rtl;
    padding: 60px 40px 85px 0;
   
}
.back-second .text p{
    color: #828687;
    margin-top: 10px;
}
.back-second h4{
    color: #2d3436;
    text-align: right;
    transform: translateY(39px);
}
.box-midddle button{
margin: 10px auto;
display: block;
color: #132a3e;
background: transparent;
border: 1px solid #132a3e;
padding: 5px 25px;
border-radius: 3px;
}
.box-midddle button:hover{
    background-color: #132a3e;
    color: white;
    cursor: pointer;
}

.back-second .brand{
    width: 100%;
    height: 250px;
    background-color: white;
    transform: translateY(77px);
    border-radius: 5px;
    text-align: center;
}
.back-second .distance{
    clear: both;
}
.back-second .brand img{
padding-top: 50px;
width: 100%;
}
.back-second h5{
    color: black;
    transform: translateY(70px);
    text-align: right;
}




/* footer */






/**************************/
@media only screen and (max-width:992px){
    .background .box-midddle{
        height: 500px;
       
       
    }
    .back-second .text{
        padding: 60px 40px 10px 0;
    }
    
}
@media only screen and (max-width:480px){
    .back-second .img img{
   width: 100%;
    }
    .background .box-midddle{
        height: 550px;
    }
    .back-second .brand{
        height: 350px;
    }
    
}

@media only screen and (max-width:768px){
    .background .back-second:after{
        display: none;
    }
    .back-second .img{
        display: none;
    }
    .background .box-midddle {
        height: auto;
        padding-bottom: 20px;
    }
    .back-second .text {
        padding: 30px 40px 10px 0;
    }
    .back-second .brand {
        transform: translateY(83px);
        height: 400px;
    }
    .background .back-second:before {
        background-color: #0095db;
        /* transform: translateY(0) rotate(30deg); */
       height: 800px;
        left: 0%;
       
        transform: translateY(0) rotate(0deg);
        width: 100%;

       
    }
    .background .back-second:before{
        top: 5%;
    }

    .background .box-midddle{
       
        transform: translateY(53px);
        }
        
        .back-second .brand img{
            padding-top: 0;
        }
       
        .back-second h5 {
            transform: translateY(70px);
        }
}

@media only screen and (max-width:446px){
    .back-second .brand {
       
        height: 185px;
    }
}


/* footer media */




