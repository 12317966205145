
.content{
    margin-top: 50px;
}
.wraper-content h3{
text-align: right;
}
.wraper-content h3{
    color: #505050;
    margin-bottom: 40px;
}
.wraper-content h3 a{
    text-decoration: none;
    color: #2ba7e1;
    font-size: 14px;
}
.wraper-content .first-box .box{
    padding: 8px;
}
.wraper-content .min-box{
  text-align: right;
  
padding: 10px;
}
.wraper-content .min-box img{
    width: 100%;
}
.wraper-content .min-box h4{
   margin-right: 15px;
   color: #484b4d;
}
.wraper-content .min-box ul{
    margin:15px 15px 0 0;
}
.wraper-content .min-box p{
    margin-right: 15px;
    color: #aaaaaa;
    font-size: 12px;
    padding: 20px 0 20px 0;
}
.wraper-content .min-box ul li{
    list-style: none;
    display: inline-block;
    margin-left: 30px;
}
.wraper-content .min-box ul li a{
    text-decoration: none;
    color: #aaaaaa;
}
.wraper-content .first-box i{
    float: right;
    margin: 25px 10px 20px 20px;
}
.wraper-content .first-box .caption{
   text-align: right;
    margin-top: 20px 0 20px 0;
   
}
.wraper-content .first-box .caption p,span{
    color: #aaaaaa;
}
.wraper-content .first-box ul li{
    display: block;
    margin-bottom: 10px;
}
