.footer{
    transform: translateY(100px);
}
.rooydad-wrapper{
    margin-top: 80px;
    
}
.rooydad-wrapper .slide{
   box-shadow: 0 0 2px 0 gray;
    background-color: white;
}
.rooydad-wrapper .list li img{
width: 15px;
margin-left: 10px;
display: inline-block;
}
.rooydad-wrapper .list li{
    list-style: none;
    margin-bottom: 20px;
}
.rooydad-wrapper .list li span{
    display: inline-block;
    margin-right: 50px;
}
.rooydad-wrapper .info{
    direction: rtl;
    padding: 10px;
}
.rooydad-wrapper .info h2{
    margin: 20px 20px 20px 0;
    color: #132a3e;
    font-size: 20px;
}
.rooydad-wrapper .info button{
    padding: 8px 25px 8px 25px;
    background-color: #132a3e;
    border: none;
    border-radius: 5px;
    color: white;
    font-weight: bold;
   float: left;
}
.rooydad-wrapper .info .list{
    margin-right: 30px;
}
.rooydad-wrapper .pic img{
    width: 100%;
}
.owl-carousel .owl-nav button.owl-next{
    display: none;
}
.owl-carousel .owl-nav button.owl-prev{
    display: none;
}

.select .options .search input{
    width: 80%;
    height: 40px;
    text-align: right;
}
.rooydad-wrapper .select{
    margin-top: 30px;
    
}

.rooydad-wrapper .select .options .search button{
    width: 20%;
    height: 40px;
    color: white;
    background-color: #132a3e;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.rooydad-wrapper .select .options .once,.twice{
    padding-top: 10px;
}
.rooydad-wrapper .select .options .once span{
   margin-left: 20px;
   
}
.rooydad-wrapper .select .options .twice span{
    margin-left: 20px;
 }
 .rooydad-wrapper .select .options{
     padding-top: 30px;
     box-shadow: 0 0 2px 0 gray;
     border-top: 5px solid #132a3e;
 }
 .rooydad-wrapper .select .part-two{
     text-align: center;
     margin-top: 30px;
     margin-bottom: 20px;
 }
 .rooydad-wrapper .select .part-two select{
     width:70%;
     background-color:#d4d9dc;
     height: 40px;
     
 }
 .rooydad-wrapper .select .part-two option{
     text-align: right;
 }
 
 .same{
    direction: rtl;
    padding: 10px;
}
.same img{
    width: 100%;
}
.same h4{
    color: #132a3e;
    font-weight: 100;
}
.same ul li{
    list-style: none;
    display: inline-block;
    margin-left: 20px;
    font-size: 13px;
    color: #999999;
}
.same span{
    font-size: 13px;
}
.same button{
    float: left;
    padding: 5px 10px 5px 10px;
    background-color: #132a3e;
    border: none;
    border-radius: 5px;
    color: white;
    outline: none;
}

.select .once select{
    width:40%;
    background-color:#d4d9dc;
}
.select .twice select{
    width:40%;
    background-color:#d4d9dc;
}
#btn{
    display: block;
    margin: 30px auto 0 auto;
    transform: translateY(20px);
    background-color: #e8eaf1;
    border: none;
    border-radius: 4px;
    padding: 10px 25px 10px 25px;
    color: #748aa7;
    outline: none;
}


 @media only screen and (max-width:480px){
    .rooydad-wrapper .select .part-two select{
        width: 100%;
        margin-bottom: 20px;
    }
    .rooydad-wrapper .select .part-two .filter span{
        display: none;
    }
    .select .twice select {
        width: 100%;
    }
    .select .once select {
        width: 100%;
    }
    .rooydad-wrapper .select .options{
        margin-top: 30px;
    }
    .rooydad-wrapper .info{
        height: 400px;
    }
    .rooydad-wrapper .slide{
        box-shadow:none;
    }
    
 }

 @media only screen and (max-width:992px){
     .select .search{
         margin-top: 20px;
         padding-top: 20px;
     }
    
 }