*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body{
    width: 100%;
    overflow-x: hidden;
}

.container{
    width: 80%;
    margin: 0 auto;
}

.container-middle{
    width: 90%;
    margin: 0 auto;
}

.container-fluid{
    width: 100%;
}

.row::after{
content: "";
clear: both;
display: table;
}




@media only screen and (max-width:480px){

.col-xs-1{
    width: 8.3333%;
    float: left;
}
.col-xs-2{
    width: 16.6666%;
    float: left;
}
.col-xs-3{
    width: 24.9999%;
    float: left;
}
.col-xs-4{
    width: 33.3333%;
    float: left;
}
.col-xs-5{
    width: 41.6666%;
    float: left;
}
.col-xs-6{
    width: 49.9999%;
    float: left;
}
.col-xs-7{
    width: 58.3333%;
    float: left;
}
.col-xs-8{
    width: 66.6666%;
    float: left;
}
.col-xs-9{
    width:74.9999%;
    float: left;
}
.col-xs-10{
    width: 83.333%;
    float: left;
}
.col-xs-11{
    width: 91.6666%;
    float: left;
}
.col-xs-12{
    width: 100%;
    float: left;
}



}


@media only screen and (min-width:480px){

    .col-sm-1{
        width: 8.3333%;
        float: left;
    }
    .col-sm-2{
        width: 16.6666%;
        float: left;
    }
    .col-sm-3{
        width: 24.9999%;
        float: left;
    }
    .col-sm-4{
        width: 33.3333%;
        float: left;
    }
    .col-sm-5{
        width: 41.6666%;
        float: left;
    }
    .col-sm-6{
        width: 49.9999%;
        float: left;
    }
    .col-sm-7{
        width: 58.3333%;
        float: left;
    }
    .col-sm-8{
        width: 66.6666%;
        float: left;
    }
    .col-sm-9{
        width:74.9999%;
        float: left;
    }
    .col-sm-10{
        width: 83.333%;
        float: left;
    }
    .col-sm-11{
        width: 91.6666%;
        float: left;
    }
    .col-sm-12{
        width: 100%;
        float: left;
    }
    
    
    
    }


    @media only screen and (min-width:768px){

        .col-md-1{
            width: 8.3333%;
            float: left;
        }
        .col-md-2{
            width: 16.6666%;
            float: left;
        }
        .col-md-3{
            width: 24.9999%;
            float: left;
        }
        .col-md-4{
            width: 33.3333%;
            float: left;
        }
        .col-md-5{
            width: 41.6666%;
            float: left;
        }
        .col-md-6{
            width: 49.9999%;
            float: left;
        }
        .col-md-7{
            width: 58.3333%;
            float: left;
        }
        .col-md-8{
            width: 66.6666%;
            float: left;
        }
        .col-md-9{
            width:74.9999%;
            float: left;
        }
        .col-md-10{
            width: 83.333%;
            float: left;
        }
        .col-md-11{
            width: 91.6666%;
            float: left;
        }
        .col-md-12{
            width: 100%;
            float: left;
        }
        
        
        
        }




        @media only screen and (min-width:992px){

            .col-lg-1{
                width: 8.3333%;
                float: left;
            }
            .col-lg-2{
                width: 16.6666%;
                float: left;
            }
            .col-lg-3{
                width: 24.9999%;
                float: left;
            }
            .col-lg-4{
                width: 33.3333%;
                float: left;
            }
            .col-lg-5{
                width: 41.6666%;
                float: left;
            }
            .col-lg-6{
                width: 49.9999%;
                float: left;
            }
            .col-lg-7{
                width: 58.3333%;
                float: left;
            }
            .col-lg-8{
                width: 66.6666%;
                float: left;
            }
            .col-lg-9{
                width:74.9999%;
                float: left;
            }
            .col-lg-10{
                width: 83.333%;
                float: left;
            }
            .col-lg-11{
                width: 91.6666%;
                float: left;
            }
            .col-lg-12{
                width: 100%;
                float: left;
            }
            
            
            
            }




            @media only screen and (min-width:1200px){

                .col-xl-1{
                    width: 8.3333%;
                    float: left;
                }
                .col-xl-2{
                    width: 16.6666%;
                    float: left;
                }
                .col-xl-3{
                    width: 24.9999%;
                    float: left;
                }
                .col-xl-4{
                    width: 33.3333%;
                    float: left;
                }
                .col-xl-5{
                    width: 41.6666%;
                    float: left;
                }
                .col-xl-6{
                    width: 49.9999%;
                    float: left;
                }
                .col-xl-7{
                    width: 58.3333%;
                    float: left;
                }
                .col-xl-8{
                    width: 66.6666%;
                    float: left;
                }
                .col-xl-9{
                    width:74.9999%;
                    float: left;
                }
                .col-xl-10{
                    width: 83.333%;
                    float: left;
                }
                .col-xl-11{
                    width: 91.6666%;
                    float: left;
                }
                .col-xl-12{
                    width: 100%;
                    float: left;
                }
                
                
                
                }