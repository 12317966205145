.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled{
    display: block;
}
.owl-theme .owl-dots .owl-dot{
    display: none;
}
.owl-carousel .owl-nav .owl-prev {
    position: absolute;
    top: 80px;
    left: 80px;
    background-color:rgb(243, 236, 236) !important;
    border-radius: 50%;
}
.owl-carousel .owl-nav .owl-next {
    position: absolute;
    top: 80px;
    right: 80px;
    outline: 0;
    background-color: rgb(245, 238, 238)!important;
    border-radius: 50%;
}
.owl-carousel .owl-nav .owl-next span {
    font-size: 50px;
    z-index: 20;
    padding: 0px 15px 0px 15px;
    
}
.owl-carousel .owl-nav .owl-next span:hover{
    color: black;
}
.owl-carousel .owl-nav .owl-prev span:hover{
    color: black;
}
.owl-carousel .owl-nav .owl-prev span {
    font-size: 50px;
    z-index: 20;
    padding: 0px 15px 0px 15px;
   
}


.header{
    margin-top: 60px;
}
.picture{
   
    height: 350px;
}
.picture img{
    width: 100%;
   
}
.picture{
    position: relative;
    z-index: -1;
   
}
.picture button{
    position: absolute;
    top: 250px;
    right: 50px;
    background-color: #132a3e;
    color: white;
    border: none;
    padding:10px 15px 10px 15px;
    border-radius: 5px;
    font-size: 18px;

}
.picture button:hover{
    background-color: #051e35;
    cursor: pointer;
}
.picture p{
    margin-left: 20px;
    color: rgb(138, 135, 135);
}
.picture ul{
    margin-left: 80px;
    margin-left: 180px;
}
.picture ul li{
    display: inline-block;
    margin-left: 10px;
    color: rgb(138, 135, 135);
    
}



@media only screen and (max-width:992px){
    .owl-theme .owl-nav{
        display: none;
    }
}

@media only screen and (max-width:480px){
   
    .box-midddle button{
        padding: 5px 10px
    }
}