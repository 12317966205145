.footer{
    background-color: #132a3e;
    clear: both;
    transform: translateY(340px);
    direction: rtl;
    height: 120vh;
}
.footer .list ul li{
list-style: none;
margin-bottom: 10px;
font-size: 12px;
}
.footer .list ul li a{
    text-decoration: none;
    color: white;
}
.footer .footer-head h2{
    color: #a8c5dd;
    font-size: 16px;
    padding: 20px 0 20px 0;
}
.second-row{
margin-top: 30px;
}
.line{
    background-color: white;
    margin: 20px 0 0 0 0;
}

.footer-head .list-third #icon img{
width: 40px;
background-color: white;
float: right;
}
.footer-head .list-third #icon button{
    background-color: transparent;
    border: 1px solid white;
    padding-left:15px;
}
.footer-head .list-third #icon button span{
    display: inline-block;
    padding: 10px 10px 0 0;
}
.footer-head .list-third #icon button:hover{
    background-color: white;
    
}
.footer-head .list-third #icon button:hover span{
    color: black;
}
.footer-head .list-third li{
    color: #93a2b9;
}
.footer-head .namad ul li{
display: inline-block;
}
.footer-head .namad #enamad{
    margin-top: 20px;
    vertical-align: -30px;
}
.footer-head .social img{
width: 20px;
display: inline-block;
margin-left: 10px;
margin-top: 20px;
}
.footer-head .social{
    text-align: left;
}
.footer-head .contact ul li{
display: inline-block;
margin-left: 20px;
margin-top: 20px;
}
.footer-head .contact ul li a{
    text-decoration: none;
    color: white;
}


@media only screen and (max-width:768px){
    .footer .footer-head .first-row{
        display: none;
    }
    .footer .footer-head .second-row{
        display: none;
    }
    .footer .footer-head .third-row{
        display: none;
    }
    .footer .footer-head .row-end .social,.contact{
        text-align: center;
    }
    .footer .line{
        display: none;
    }
    .footer{
        height: 30vh;
        transform: translateY(239px);
    }
}