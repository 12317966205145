.head .search img{
    width: 13px;
    
}
.head .search{
    background-color: #eeeeee;
    text-align: center;
    height: 100px;
    padding-top: 30px;
}
.head{
    margin-top: 75px;
}
.head h2{
    text-align: center;
    margin-bottom: 20px;
}
.head .search input{
    width: 50%;
    height: 40px;
    text-align: right;
}
.head .search button{
    padding: 10px 45px 10px 45px;
    border: none;
    background-color: #132a3e;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.box-info{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
margin-top: 30px;
}
.box-info #btn{
    display: block;
    margin: 30px auto 0 auto;
    transform: translateY(20px);
    background-color: #e8eaf1;
    border: none;
    border-radius: 4px;
    padding: 10px 25px 10px 25px;
    color: #748aa7;
    outline: none;
}
.box-info .sub-box{
    direction: rtl;
    padding: 20px;
    border: 1px solid #a3a3ab44;
    flex-basis: 32%;
    margin-bottom: 20px;
}
.box-info .sub-box img{
    border-radius: 50%;
    border: 1px solid gray;
    display: inline-block;
}
.box-info .sub-box h4{
    display: inline-block;
    margin-right: 10px;
    vertical-align: 20px;
    color: #132a3e;
}
.box-info .sub-box ul li{
    display: inline-block;
    margin-left: 15px;
   font-size: 15px;
   margin-top: 20px;
   color: #a3a3a3;
}
.box-info .sub-box ul span{
    color: black;
}
.box-info .sub-box ul li:not(:nth-child(2)){
    border-left: 1px solid gray;
    padding-left: 10px;
}


@media only screen and (max-width:480px){
    .box-info .sub-box{
        flex-basis: 100%;
        margin-bottom: 20px;
    }
    .head .search button {
        padding: 10px 15px 10px 15px;
    }
    .head .search input {
        width: 75%;
    }
    .box-info .sub-box ul li {
        font-size: 11px;
    }
}
@media only screen and (min-width:480px) and (max-width:768px){
    .box-info .sub-box{
        flex-basis: 100%;
        margin-bottom: 20px;
    }
}
@media only screen and (min-width:768px) and (max-width:992px){
    .box-info .sub-box{
        flex-basis: 45%;
        margin-bottom: 20px;

    }
    .box-info .sub-box ul li {
        font-size: 12px;
    }
}
@media only screen and (min-width:992px) and (max-width:1150px){
    .box-info .sub-box ul li {
        font-size: 12px;
    }
}