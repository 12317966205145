.titr{
    background-image: url(../img/recommandation-bar.png);
    height: 85px;
    background-repeat: no-repeat;
}
.titr-main{
    margin-top: 60px;
}
.titr button{
    border-radius: 3px;
    font-size: 14px;
    line-height: 1.75;
    padding: 7px 34px;
    border: none;
margin-top: 25px;
margin-left: 20px;
}
.titr .btn1{
    background-color: #e8eaf1;
   
    color: #748494;
}
.titr .btn2{
    background-color: #132a3e;
    color: white;
}
.titr .titr-p{
    direction: rtl;
    padding: 25px 30px 0 0;
}
.titr .titr-p p{
    color: #333a3c;
    font-size: 16px;
}






@media only screen and (max-width:768px){
    .titr{
        height: 250px;
        padding: 10px;
    }
    .titr button{
        width: 92%;
    }
}